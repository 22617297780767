// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-jsx": () => import("./../../../src/pages/page.jsx" /* webpackChunkName: "component---src-pages-page-jsx" */),
  "component---src-pages-products-shopify-product-product-type-index-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/index.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-index-jsx" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

